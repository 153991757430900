import "../CSS/Ploeg.css";

function U11() {
  return (
    <div className="ploegoverzicht">
      <iframe
        className="klassement"
        src="https://www.voetbalvlaanderen.be/competitie/CHP_122405/overzicht"
      />
    </div>
  );
}

export default U11;
