import "../CSS/Ploeg.css";

function U10() {
  return (
    <div className="ploegoverzicht">
      <iframe
        className="klassement"
        src="https://www.voetbalvlaanderen.be/competitie/CHP_121122/overzicht"
      />
    </div>
  );
}

export default U10;
