import "../CSS/Ploeg.css";

function U13() {
  return (
    <div className="ploegoverzicht">
      <iframe
        className="klassement"
        src="https://www.voetbalvlaanderen.be/competitie/CHP_120040/overzicht"
      />
    </div>
  );
}

export default U13;
