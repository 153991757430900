import "../CSS/Ploeg.css";

function U12() {
  return (
    <div className="ploegoverzicht">
      <iframe
        className="klassement"
        src="https://www.voetbalvlaanderen.be/competitie/CHP_120855/overzicht"
      />
    </div>
  );
}

export default U12;
